import { formatPrice } from "@/utils/format";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Image from "next/image";
import { useTranslation } from "react-i18next";
import useTradeInStore from "../hooks/useTradeInStore";
export function TradeInResult() {
  const {
    t
  } = useTranslation();
  const {
    diagnosticQuoted,
    selectedCategory,
    selectedVariant,
    step,
    voucher
  } = useTradeInStore();
  const quotedPrice = diagnosticQuoted?.quotedPrice || 0;
  const voucherAmount = voucher?.amount || 0;
  const totalValue = quotedPrice + voucherAmount;
  return <div className="mb-8 flex items-center gap-4 rounded-xl bg-gray-50 p-4" data-sentry-component="TradeInResult" data-sentry-source-file="TradeInResult.tsx">
      <div className="w-24 flex-none">
        {/* <Image
          alt=""
          className="mx-auto mb-2 aspect-square"
          height={60}
          loading="lazy"
          src="/img/shuffle.svg"
          width={60}
         /> */}
        <Image alt={selectedCategory?.productCategoryName || ""} className="mx-auto mb-2 aspect-square" height={60} src={selectedCategory?.productCategoryPrefix === "T" ? `/img/tablet-icon.png` : selectedCategory?.productCategoryPrefix === "L" ? `/img/laptop-icon.png` : selectedCategory?.productCategoryPrefix === "W" ? `/img/watch-icon.png` : `/img/smartphone-icon.png`} width={60} data-sentry-element="Image" data-sentry-source-file="TradeInResult.tsx" />
      </div>
      <div className="flex-1">
        <span className="block text-base font-bold">
          {selectedVariant?.productFullName}
        </span>
        {quotedPrice > 0 && <div className="flex justify-between text-xs leading-tight">
            <span>{t("tradeIn.card.potentialValue")}</span>
            <span>{formatPrice(quotedPrice)}</span>
          </div>}
        {voucherAmount > 0 && <div className="flex justify-between text-xs leading-tight">
            <span className="text-xs leading-tight">
              {t("tradeIn.card.congratulations")} 🎉
            </span>
            <span>{formatPrice(voucherAmount)}</span>
          </div>}
        {quotedPrice > 0 && <>
            <div className="mt-3 flex justify-between text-sm font-bold leading-tight text-black">
              <span className="">{t("tradeIn.card.totalValue")}</span>
              <span>{formatPrice(totalValue)}</span>
            </div>
            <span className="block text-xs italic text-gray-600">
              ({t("tradeIn.card.terms")})
            </span>
          </>}
        {quotedPrice === 0 && step === 3 && <>
            <div className="flex justify-between text-xs leading-tight">
              <span>{t("tradeIn.card.potentialValue")}</span>
              <span>{formatPrice(quotedPrice)}</span>
            </div>
            <div className="mt-4 flex items-center gap-4 rounded-lg border border-dashed border-gray-400 bg-white p-4">
              <ExclamationCircleOutlined className="text-xl" />
              <span className="block text-sm leading-tight">
                {t("tradeIn.stepThree.deviceIneligible")}
              </span>
            </div>
          </>}
      </div>
    </div>;
}
export default TradeInResult;