import { checkCampaignIsActive } from "@/utils";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { Configuration } from "../type";

type ConfigurationState = {
  isCampaignActive: boolean;
} & Configuration;

type ConfigurationAction = {
  setConfiguration: (configuration: Configuration) => void;
};

const initialState: ConfigurationState = {
  alertMessage: "",
  alertMessageEn: "",
  campaignEndDate: undefined,
  campaignProducts: [],
  campaignStartDate: undefined,
  checkoutSubTitle: "",
  checkoutSubTitleEn: "",
  checkoutTitle: "",
  checkoutTitleEn: "",
  configuration: {
    depositAmount: "",
    enableDeposit: false,
    pickupLocations: [],
  },
  deliveryDescription: "",
  deliveryDescriptionEn: "",
  enableDeliveryShipping: false,
  enableSchedulePickupDateTime: false,
  foreignerPopUpMessage: "",
  foreignerPopUpMessageEn: "",
  isCampaignActive: false,
  pickupDescription: "",
  pickupDescriptionEn: "",
  productSettingsForIpad: {
    enable: false,
    products: [],
    subTitle: "",
    subTitleEn: "",
    termAndCondition: "",
    termAndConditionEn: "",
    title: "",
    titleEn: "",
  },
  productSettingsForIphone: {
    enable: false,
    products: [],
    subTitle: "",
    subTitleEn: "",
    termAndCondition: "",
    termAndConditionEn: "",
    title: "",
    titleEn: "",
  },
  productSettingsForMac: {
    enable: false,
    products: [],
    subTitle: "",
    subTitleEn: "",
    termAndCondition: "",
    termAndConditionEn: "",
    title: "",
    titleEn: "",
  },
  productSettingsForWatch: {
    enable: false,
    products: [],
    subTitle: "",
    subTitleEn: "",
    termAndCondition: "",
    termAndConditionEn: "",
    title: "",
    titleEn: "",
  },
  promotionBanners: "",
  promotionBannersEn: "",
  siteDescription: "",
  siteTitle: "",
  subTitle: "",
  subTitleEn: "",
  suggestVideos: [],
  temporarilyClosed: false,
  title: "",
  titleEn: "",
  topBanners: [],
};

export const useConfigurationStore = create(
  persist<ConfigurationAction & ConfigurationState>(
    (set, get) => ({
      ...initialState,
      setConfiguration: (configuration: Configuration) =>
        set(() => ({
          ...get(),
          ...configuration,
          isCampaignActive: checkCampaignIsActive({
            campaignEndDate: configuration.campaignEndDate as unknown as string,
            campaignStartDate:
              configuration.campaignStartDate as unknown as string,
          }),
        })),
    }),
    {
      name: "configurationStorage",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

export default useConfigurationStore;
