import { Button, Form, Radio, Select, Spin } from "antd";
import Image from "next/image";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import QuestionTitle from "./components/QuestionTitle";
import Stepper from "./components/Stepper";
import { useTradeIn } from "./hooks/useTradeIn";
import useTradeInStore from "./hooks/useTradeInStore";
export type SelectDeviceFormState = {
  band: string | undefined;
  category: string | undefined;
  model: string | undefined;
  variant: string | undefined;
};
export function SelectDeviceForm() {
  const {
    t
  } = useTranslation();
  const [form] = Form.useForm<SelectDeviceFormState>();
  const category = Form.useWatch("category", form);
  const band = Form.useWatch("band", form);
  const model = Form.useWatch("model", form);
  const {
    data: categories,
    isLoading: isCategoriesLoading
  } = useTradeIn().getProductCategories();
  const {
    data: brands,
    isLoading: isBrandsLoading
  } = useTradeIn().getProductBrands(category!);
  const {
    data: models,
    isLoading: isModelsLoading
  } = useTradeIn().getProductModels(band!);
  const {
    data: variants,
    isLoading: isVariantsLoading
  } = useTradeIn().getProductVariants(model!);
  const {
    formValues,
    setCategory,
    setFormValues,
    setSelectedBrand,
    setSelectedModel,
    setSelectedVariant,
    setStep,
    step
  } = useTradeInStore();
  useEffect(() => {
    if (category && formValues.selectedDevice?.category !== category) {
      form.setFieldsValue({
        band: undefined
      });
      form.setFieldsValue({
        model: undefined
      });
      form.setFieldsValue({
        variant: undefined
      });
    }
  }, [category, form, formValues.selectedDevice?.category]);
  useEffect(() => {
    if (band && formValues.selectedDevice?.band !== band) {
      form.setFieldsValue({
        model: undefined
      });
      form.setFieldsValue({
        variant: undefined
      });
    }
  }, [band, category, form, formValues.selectedDevice?.band]);
  useEffect(() => {
    if (model && formValues.selectedDevice?.model !== model) {
      form.setFieldsValue({
        variant: undefined
      });
    }
  }, [band, model, form, formValues.selectedDevice?.model]);
  const handleOnSubmit = useCallback((values: SelectDeviceFormState) => {
    const selectedCategory = categories?.data?.find(category => category.productCategoryId === Number(values.category));
    const selectedBrand = brands?.data?.find(brand => brand.productGroupId === Number(values.band));
    const selectedModel = models?.data?.find(model => model.productId === Number(values.model));
    const selectedVariant = variants?.data?.find(variant => variant.productVariantId === Number(values.variant));
    setCategory(selectedCategory!);
    setSelectedBrand(selectedBrand!);
    setSelectedModel(selectedModel!);
    setSelectedVariant(selectedVariant!);
    setFormValues({
      ...formValues,
      selectedDevice: values
    });
    setStep(2);
  }, [brands?.data, categories?.data, formValues, models?.data, setCategory, setFormValues, setSelectedBrand, setSelectedModel, setSelectedVariant, setStep, variants?.data]);
  return <Spin spinning={isCategoriesLoading || isBrandsLoading || isModelsLoading || isVariantsLoading} data-sentry-element="Spin" data-sentry-component="SelectDeviceForm" data-sentry-source-file="SelectDeviceForm.tsx">
      <Form autoComplete="off" form={form} initialValues={formValues.selectedDevice} labelWrap layout="vertical" onFinish={handleOnSubmit} requiredMark="optional" data-sentry-element="Form" data-sentry-source-file="SelectDeviceForm.tsx">
        <div className="mb-4 space-y-4">
          <QuestionTitle description={t("tradeIn.stepOne.title")} title={t("tradeIn.stepOne.title")} data-sentry-element="QuestionTitle" data-sentry-source-file="SelectDeviceForm.tsx" />
          <Stepper count={3} step={step} data-sentry-element="Stepper" data-sentry-source-file="SelectDeviceForm.tsx" />
        </div>
        <Form.Item<SelectDeviceFormState> name="category" rules={[{
        message: t("tradeIn.stepOne.category.validate.required"),
        required: true
      }]} data-sentry-element="unknown" data-sentry-source-file="SelectDeviceForm.tsx">
          <Radio.Group className="mt-6 grid grid-cols-2 gap-2 [&>label]:before:hidden" optionType="button" data-sentry-element="unknown" data-sentry-source-file="SelectDeviceForm.tsx">
            {categories?.data?.map(item => <Radio.Button className="h-auto rounded-2xl border p-4 text-center leading-tight" key={item.productCategoryId} value={item.productCategoryId}>
                <div>
                  <Image alt={item.productCategoryName} className="mx-auto mb-2 aspect-square" height={80} src={item.productCategoryPrefix === "T" ? `/img/tablet-icon.png` : item.productCategoryPrefix === "L" ? `/img/laptop-icon.png` : item.productCategoryPrefix === "W" ? `/img/watch-icon.png` : `/img/smartphone-icon.png`} width={80} />
                  <span className="block font-semibold">
                    {item.productCategoryPrefix === "T" && t("tradeIn.category.tablet")}
                    {item.productCategoryPrefix === "L" && t("tradeIn.category.laptop")}
                    {item.productCategoryPrefix === "W" && t("tradeIn.category.watch")}
                    {item.productCategoryPrefix === "S" && t("tradeIn.category.smartphone")}
                  </span>
                </div>
              </Radio.Button>)}
          </Radio.Group>
        </Form.Item>
        <Form.Item<SelectDeviceFormState> label={t("tradeIn.stepOne.brand.label")} name="band" rules={[{
        message: t("tradeIn.stepOne.brand.validate.required"),
        required: true
      }]} data-sentry-element="unknown" data-sentry-source-file="SelectDeviceForm.tsx">
          <Select className="w-full" disabled={!category} optionFilterProp="label" options={brands?.data?.map(brand => ({
          label: brand.productGroupName,
          value: brand.productGroupId
        }))} placeholder={t("tradeIn.stepOne.brand.placeholder")} data-sentry-element="Select" data-sentry-source-file="SelectDeviceForm.tsx" />
        </Form.Item>
        <Form.Item<SelectDeviceFormState> label={t("tradeIn.stepOne.model.label")} name="model" rules={[{
        message: t("tradeIn.stepOne.model.validate.required"),
        required: true
      }]} data-sentry-element="unknown" data-sentry-source-file="SelectDeviceForm.tsx">
          <Select className="w-full" disabled={!band} optionFilterProp="label" options={models?.data?.map(model => ({
          label: model.productName,
          value: model.productId
        }))} placeholder={t("tradeIn.stepOne.model.placeholder")} data-sentry-element="Select" data-sentry-source-file="SelectDeviceForm.tsx" />
        </Form.Item>
        <Form.Item<SelectDeviceFormState> label={t("tradeIn.stepOne.variant.label")} name="variant" rules={[{
        message: t("tradeIn.stepOne.variant.validate.required"),
        required: true
      }]} data-sentry-element="unknown" data-sentry-source-file="SelectDeviceForm.tsx">
          <Select className="w-full" disabled={!model} optionFilterProp="label" options={variants?.data?.map(variant => ({
          label: variant.productVariantName,
          value: variant.productVariantId
        }))} placeholder={t("tradeIn.stepOne.variant.placeholder")} data-sentry-element="Select" data-sentry-source-file="SelectDeviceForm.tsx" />
        </Form.Item>
        <Button className="w-full" htmlType="submit" type="primary" data-sentry-element="Button" data-sentry-source-file="SelectDeviceForm.tsx">
          {t("tradeIn.next")}
        </Button>
      </Form>
    </Spin>;
}
export default SelectDeviceForm;