"use client";

import { Modal } from "antd";
import { useEffect, useRef } from "react";
import useTradeInStore from "./hooks/useTradeInStore";
import TradeInForm from "./TradeInForm";
export function TradeInModal() {
  const {
    closeModal,
    isDone,
    isModalOpen,
    reset,
    step
  } = useTradeInStore();
  const handleCancel = () => {
    if (!isDone) {
      reset();
    }
    closeModal();
  };
  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const tradeInModals = document.querySelectorAll(".tradeInModal");
      tradeInModals?.forEach(tradeInModal => {
        tradeInModal.scrollTo({
          behavior: "smooth",
          top: 0
        });
      });
    }
  }, [step]);
  return <Modal centered closable footer={null}
  // onOk={handleOk}
  onCancel={handleCancel} open={isModalOpen} width={600} wrapClassName="tradeInModal" data-sentry-element="Modal" data-sentry-component="TradeInModal" data-sentry-source-file="TradeInModal.tsx">
      <div className="mt-4"></div>
      <TradeInForm data-sentry-element="TradeInForm" data-sentry-source-file="TradeInModal.tsx" />
    </Modal>;
}
export default TradeInModal;