/**
 * It takes a number and returns a string with a comma as a thousand separator
 * @param {number} price - number - The price to format
 * @returns A function that takes a number and returns a string.
 */
export function formatPrice(price: number): string {
  return new Intl.NumberFormat("en-US").format(price) + ".-";
}

/**
 * It takes a string, converts it to lowercase, and then replaces any non-alphanumeric characters with
 * the next character capitalized
 * @param {string} str - The string to convert to camel case.
 * @returns A function that takes a string and returns a string.
 */
export function toCamelCase(str: string): string {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

export function formatDate(
  date: Date | string,
  local: "en-US" | "th-TH" = "th-TH",
): string {
  const newDate = new Date(date);
  return new Intl.DateTimeFormat(local, {
    day: "numeric",
    hour: "numeric",
    hour12: false,
    minute: "numeric",
    month: "long",
    second: "numeric",
    weekday: "long",
    year: "numeric",
  }).format(newDate);
}

const toDate = (value: Date | number | string): Date => {
  return typeof value === "string" || typeof value === "number"
    ? new Date(value)
    : value;
};

export const formatThaiDate = (
  value: Date | number | string,
  local: "en" | "th" = "th",
): string => {
  const date = toDate(value);
  const month = date.toLocaleString(local, { month: "long" });
  const day = date.getDate();
  const year = date.getFullYear() + 543;

  return `${month} ${day}, ${year}`;
};

export const formatThaiDateWithTime = (
  value: Date | number | string,
  local: "en" | "th" = "th",
): string => {
  const date = toDate(value);
  const month = date.toLocaleString(local, { month: "long" });
  const day = date.getDate();
  const year = date.getFullYear() + 543;
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${month} ${day}, ${year} ${hours}:${minutes}`;
};
