import useSession from "@/hooks/useSession";
import { phoneRegex } from "@/lib/constants";
import { clearHtmlTags } from "@/utils";
import { Button, Checkbox, Form, Input, Radio, Select } from "antd";
import _ from "lodash";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import useCart from "../cart/hook/useCart";
import { updateTradeInInformation } from "../cart/lib/action";
import { useCartStore } from "../cart/store/useCartStore";
import QuestionTitle from "./components/QuestionTitle";
import Stepper from "./components/Stepper";
import TradeInResult from "./components/TradeInResult";
import { useTradeIn } from "./hooks/useTradeIn";
import useTradeInStore, { FormState } from "./hooks/useTradeInStore";
import { services } from "./libs/service";
import { TradeInOrder } from "./libs/type";
export type SelectDeliverFormState = {
  acceptTermsAndConditions: boolean;
  bankAccountName: string;
  bankAccountNo: string;
  bankId: string;
  customerEmail: string;
  customerEmailConfirm: string;
  customerMobilePhone: string;
  customerName: string;
  dropoffStoreId?: string;
  imeiSerialNo: string;
  pickupDropoffType?: "dropoff" | "pickup";
  postcode?: string;
};
export function SelectDeliverForm() {
  const {
    t
  } = useTranslation();
  const {
    session
  } = useSession();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    getCart
  } = useCart();
  const {
    setCart
  } = useCartStore(state => state);
  const {
    action,
    cartItemKey,
    closeModal,
    diagnosticQuoted,
    formValues,
    isDone,
    reset,
    selectedBrand,
    selectedCategory,
    selectedModel,
    selectedVariant,
    setDone,
    setFormValues,
    setTradeInOrder,
    step,
    voucher
  } = useTradeInStore();
  const handleUpdateCartItem = useCallback(async (cartItemKey: string, formValues: FormState) => {
    const tradeInInformation = {
      cartItemKey,
      tradeInInformation: JSON.stringify({
        diagnosticQuoted,
        formValues,
        selectedBrand,
        selectedCategory,
        selectedModel,
        selectedVariant,
        voucher
      })
    };
    if (action === "create") {
      await updateTradeInInformation(tradeInInformation).then(async response => {
        if (!response.success) {
          toast.error(clearHtmlTags(response.error));
        }
        if (response.success) {
          await getCart().then(response => response?.success && setCart(response.data));
          reset();
        }
      });
    }
    if (action === "update") {
      await updateTradeInInformation(tradeInInformation).then(async response => {
        if (!response.success) {
          toast.error(clearHtmlTags(response.error));
        }
        if (response.success) {
          await getCart().then(response => response?.success && setCart(response.data));
          reset();
        }
      });
    }
  }, [action, diagnosticQuoted, getCart, reset, selectedBrand, selectedCategory, selectedModel, selectedVariant, setCart, voucher]);
  async function handleOnSubmit(values: SelectDeliverFormState) {
    setIsSubmitting(true);
    const order: TradeInOrder = {
      ...values,
      bankId: Number(values.bankId),
      deviceDiagnosticUuid: diagnosticQuoted?.deviceDiagnosticUuid ?? "",
      dropoffStoreId: values.dropoffStoreId ? Number(values.dropoffStoreId) : null,
      pickupDropoffType: values.pickupDropoffType!,
      postcode: values.postcode ? values.postcode : null,
      voucherId: voucher?.voucherId ?? null
    };
    const newFormValues: FormState = {
      ...formValues,
      selectDeliver: values
    };
    setTradeInOrder(order);
    setFormValues(newFormValues);
    setDone(true);
    if (cartItemKey) {
      await handleUpdateCartItem(cartItemKey, newFormValues);
    }
    if (action === "create") {
      toast.success("Thank you for your joining trade in program.");
    }
    if (action === "update") {
      toast.success("Updated successfully.");
    }
    setIsSubmitting(false);
    closeModal();
  }
  const customerName = session.isLoggedIn ? (session?.firstName || "") + " " + (session?.lastName || "") : "";
  const isDiagnosticQuotedZero = _.get(diagnosticQuoted, "quotedPrice", 0) === 0;

  // @ts-expect-error - type is null but it should be a string or undefined
  const initialValues: SelectDeliverFormState = {
    ...formValues.selectDeliver,
    customerEmail: formValues.selectDeliver?.customerEmail || session?.email!,
    customerEmailConfirm: formValues.selectDeliver?.customerEmail || session?.email!,
    customerMobilePhone: formValues.selectDeliver?.customerMobilePhone || session?.phoneNumber!,
    customerName: formValues.selectDeliver?.customerName || customerName
  };
  const [form] = Form.useForm<SelectDeliverFormState>();
  const {
    data: dropoffLocations,
    isLoading
  } = useTradeIn().getDropoffLocations();
  const {
    data: bankList,
    isLoading: isBankListLoading
  } = useTradeIn().getBankList();
  const {
    validateIMEI,
    validatePostcode
  } = services;
  const imeiSerialNoLabel = selectedCategory?.productCategoryPrefix !== "S" ? t("tradeIn.stepThree.serialNumber.label") : t("tradeIn.stepThree.imei.label");
  const handleValidateIMEI = async (value: string) => {
    try {
      if (value?.length < 6) {
        return Promise.resolve();
      }
      const response = await validateIMEI(value, formValues.selectedDevice?.variant!);
      if (response.data.isValid === false) {
        throw new Error(`Your ${imeiSerialNoLabel} is invalid.`);
      }
      return Promise.resolve();
    } catch (error: any | Error) {
      return Promise.reject(error?.message);
    }
  };
  const pickupDropoffType = Form.useWatch("pickupDropoffType", form);
  const handleValidatePostalCode = async (value: string) => {
    try {
      if (value?.length !== 5) {
        return Promise.resolve();
      }
      const response = await validatePostcode(value);
      if (response.data.isValid === false) {
        throw new Error("Sorry, we do not provide service in this area.");
      }
      return Promise.resolve();
    } catch (error: any | Error) {
      return Promise.reject(error?.message);
    }
  };
  return <Form autoComplete="off" disabled={isDiagnosticQuotedZero} form={form} initialValues={initialValues} labelWrap layout="vertical" onFinish={handleOnSubmit} requiredMark="optional" data-sentry-element="Form" data-sentry-component="SelectDeliverForm" data-sentry-source-file="SelectDeliverForm.tsx">
      <div className="mb-4 space-y-4">
        <QuestionTitle description={t("tradeIn.stepThree.description")} title={t("tradeIn.stepThree.title")} data-sentry-element="QuestionTitle" data-sentry-source-file="SelectDeliverForm.tsx" />
        <Stepper count={3} step={step} data-sentry-element="Stepper" data-sentry-source-file="SelectDeliverForm.tsx" />
      </div>
      <TradeInResult data-sentry-element="TradeInResult" data-sentry-source-file="SelectDeliverForm.tsx" />
      <Form.Item extra={selectedCategory?.productCategoryPrefix !== "S" ? t("tradeIn.stepThree.serialNumber.description") : t("tradeIn.stepThree.imei.description")} hasFeedback label={imeiSerialNoLabel} name="imeiSerialNo" rules={[{
      message: `Please enter the ${imeiSerialNoLabel}`,
      required: true
    }, {
      async validator(_, value) {
        if (selectedCategory?.productCategoryPrefix !== "S") {
          await handleValidateIMEI(value);
          return Promise.resolve();
        }
        if (value?.length !== 15) {
          return Promise.reject("IMEI must be 15 characters");
        }
        if (!/^\d+$/.test(value)) {
          return Promise.reject("IMEI must be numeric");
        }
        await handleValidateIMEI(value);
        return Promise.resolve();
      }
    }]} data-sentry-element="unknown" data-sentry-source-file="SelectDeliverForm.tsx">
        <Input maxLength={255} placeholder={imeiSerialNoLabel} data-sentry-element="Input" data-sentry-source-file="SelectDeliverForm.tsx" />
      </Form.Item>
      <Form.Item label={t("tradeIn.stepThree.delivery.label")} name="pickupDropoffType" rules={[{
      message: t("tradeIn.stepThree.delivery.validate.required"),
      required: true
    }]} data-sentry-element="unknown" data-sentry-source-file="SelectDeliverForm.tsx">
        <Radio.Group className="grid grid-cols-2 [&>*]:text-center" optionType="button" data-sentry-element="unknown" data-sentry-source-file="SelectDeliverForm.tsx">
          <Radio value="pickup" data-sentry-element="Radio" data-sentry-source-file="SelectDeliverForm.tsx">{t("tradeIn.stepThree.delivery.pickup")}</Radio>
          <Radio value="dropoff" data-sentry-element="Radio" data-sentry-source-file="SelectDeliverForm.tsx">
            {t("tradeIn.stepThree.delivery.delivery")}
          </Radio>
        </Radio.Group>
      </Form.Item>
      {pickupDropoffType === "dropoff" && <Form.Item label={t("tradeIn.stepThree.dropOffStore.label")} name="dropoffStoreId" rules={[{
      message: t("tradeIn.stepThree.dropOffStore.validate.required"),
      required: true
    }]}>
          <Select loading={isLoading} placeholder={t("tradeIn.stepThree.dropOffStore.placeholder")}>
            {dropoffLocations?.data?.map(location => <Select.Option key={location.dropoffStoreId} value={location.dropoffStoreId}>
                {location.storeName}
              </Select.Option>)}
          </Select>
        </Form.Item>}
      {pickupDropoffType === "pickup" && <Form.Item extra={t("tradeIn.stepThree.postalCode.description")} hasFeedback label={t("tradeIn.stepThree.postalCode.label")} name="postcode" rules={[{
      message: t("tradeIn.stepThree.postalCode.validate.required"),
      required: true
    }, {
      len: 5,
      message: "Postal code must be 5 characters"
    }, {
      message: "Postal code must be numeric",
      pattern: /^[0-9]*$/
    }, {
      async validator(_, value) {
        await handleValidatePostalCode(value);
      }
    }]}>
          <Input maxLength={5} placeholder={t("tradeIn.stepThree.postalCode.placeholder")} />
        </Form.Item>}
      <div className="my-6 h-[1px] w-full bg-gray-200"></div>
      <QuestionTitle description={t("tradeIn.stepThree.confirmation.description")} title={t("tradeIn.stepThree.confirmation.title")} data-sentry-element="QuestionTitle" data-sentry-source-file="SelectDeliverForm.tsx" />
      <div className="mb-6"></div>
      <Form.Item label={t("tradeIn.stepThree.name.label")} name="customerName" rules={[{
      message: t("tradeIn.stepThree.name.validate.required"),
      required: true
    }, {
      message: t("tradeIn.stepThree.name.validate.alphabet"),
      pattern: /^[a-zA-Z\u0E00-\u0E7F\s]*$/
    }]} data-sentry-element="unknown" data-sentry-source-file="SelectDeliverForm.tsx">
        <Input maxLength={255} placeholder={t("tradeIn.stepThree.name.placeholder")} data-sentry-element="Input" data-sentry-source-file="SelectDeliverForm.tsx" />
      </Form.Item>
      <Form.Item label={t("tradeIn.stepThree.email.label")} name="customerEmail" rules={[{
      message: t("tradeIn.stepThree.email.validate.required"),
      required: true
    }, {
      message: t("tradeIn.stepThree.email.validate.email"),
      type: "email"
    }]} data-sentry-element="unknown" data-sentry-source-file="SelectDeliverForm.tsx">
        <Input placeholder={t("tradeIn.stepThree.email.placeholder")} data-sentry-element="Input" data-sentry-source-file="SelectDeliverForm.tsx" />
      </Form.Item>
      <Form.Item dependencies={["customerEmail"]} label={t("tradeIn.stepThree.confirmEmail.label")} name="customerEmailConfirm" rules={[{
      message: t("tradeIn.stepThree.confirmEmail.validate.required"),
      required: true
    }, {
      message: t("tradeIn.stepThree.confirmEmail.validate.email"),
      type: "email"
    }, ({
      getFieldValue
    }) => ({
      validator(_, value) {
        if (!value || getFieldValue("customerEmail") === value) {
          return Promise.resolve();
        }
        return Promise.reject(t("tradeIn.stepThree.confirmEmail.validate.mismatch"));
      }
    })]} data-sentry-element="unknown" data-sentry-source-file="SelectDeliverForm.tsx">
        <Input placeholder={t("tradeIn.stepThree.confirmEmail.placeholder")} data-sentry-element="Input" data-sentry-source-file="SelectDeliverForm.tsx" />
      </Form.Item>
      <Form.Item label={t("tradeIn.stepThree.mobilePhone.label")} name="customerMobilePhone" rules={[{
      message: t("tradeIn.stepThree.mobilePhone.validate.required"),
      required: true
    }, {
      message: t("tradeIn.stepThree.mobilePhone.validate.phone"),
      pattern: phoneRegex
    }]} data-sentry-element="unknown" data-sentry-source-file="SelectDeliverForm.tsx">
        <Input maxLength={10} placeholder={t("tradeIn.stepThree.mobilePhone.placeholder")} data-sentry-element="Input" data-sentry-source-file="SelectDeliverForm.tsx" />
      </Form.Item>
      <Form.Item label={t("tradeIn.stepThree.bank.label")} name="bankId" rules={[{
      message: t("tradeIn.stepThree.bank.validate.required"),
      required: true
    }]} data-sentry-element="unknown" data-sentry-source-file="SelectDeliverForm.tsx">
        <Select loading={isBankListLoading} placeholder={t("tradeIn.stepThree.bank.placeholder")} data-sentry-element="Select" data-sentry-source-file="SelectDeliverForm.tsx">
          {bankList?.data?.map(bank => <Select.Option key={bank.bankId} value={bank.bankId}>
              {bank.bankName}
            </Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item label={t("tradeIn.stepThree.bankAccountNumber.label")} name="bankAccountNo" rules={[{
      message: t("tradeIn.stepThree.bankAccountNumber.validate.required"),
      required: true
    }, {
      message: t("tradeIn.stepThree.bankAccountNumber.validate.numeric"),
      pattern: /^[0-9]*$/
    }]} data-sentry-element="unknown" data-sentry-source-file="SelectDeliverForm.tsx">
        <Input placeholder={t("tradeIn.stepThree.bankAccountNumber.placeholder")} data-sentry-element="Input" data-sentry-source-file="SelectDeliverForm.tsx" />
      </Form.Item>
      <Form.Item label={t("tradeIn.stepThree.bankAccountName.label")} name="bankAccountName" rules={[{
      message: t("tradeIn.stepThree.bankAccountName.validate.required"),
      required: true
    }, {
      message: t("tradeIn.stepThree.bankAccountName.validate.alphabet"),
      pattern: /^[a-zA-Z\u0E00-\u0E7F\s]*$/
    }]} data-sentry-element="unknown" data-sentry-source-file="SelectDeliverForm.tsx">
        <Input placeholder={t("tradeIn.stepThree.bankAccountName.placeholder")} data-sentry-element="Input" data-sentry-source-file="SelectDeliverForm.tsx" />
      </Form.Item>
      <Form.Item name="acceptTermsAndConditions" rules={[{
      validator: (_, value) => value ? Promise.resolve() : Promise.reject(t("tradeIn.stepThree.acceptTerms.validate.required"))
    }]} valuePropName="checked" data-sentry-element="unknown" data-sentry-source-file="SelectDeliverForm.tsx">
        <Checkbox className="leading-tight" data-sentry-element="Checkbox" data-sentry-source-file="SelectDeliverForm.tsx">
          {t("tradeIn.stepThree.acceptTerms.label")}{" "}
          <a href="https://www.uficon.com/terms-of-use" target="_blank">
            {t("tradeIn.stepThree.acceptTerms.terms")}
          </a>{" "}
          {t("tradeIn.stepThree.acceptTerms.and")}{" "}
          <a href="https://www.uficon.com/privacy-policy" target="_blank">
            {t("tradeIn.stepThree.acceptTerms.privacy")}
          </a>
        </Checkbox>
      </Form.Item>
      <div className="flex justify-between gap-2">
        <Button className="w-full" htmlType="submit" loading={isSubmitting} type="primary" data-sentry-element="Button" data-sentry-source-file="SelectDeliverForm.tsx">
          {isDone ? t("tradeIn.update") : t("tradeIn.submit")}
        </Button>
      </div>
    </Form>;
}
export default SelectDeliverForm;