import { create } from "zustand";

import {
  Brand,
  DiagnosticQuoted,
  Model,
  ProductCategory,
  TradeInOrder,
  Variant,
  Voucher,
} from "../libs/type";
import { ProductDiagnosticFormState } from "../ProductDiagnosticForm";
import { SelectDeliverFormState } from "../SelectDeliverForm";
import { SelectDeviceFormState } from "../SelectDeviceForm";

type Action = "create" | "update";

export type FormState = {
  productDiagnostic?: ProductDiagnosticFormState;
  selectDeliver?: SelectDeliverFormState;
  selectedDevice?: SelectDeviceFormState;
};

export type TradeInState = {
  action: Action;
  cartItemKey?: string;
  diagnosticQuoted: DiagnosticQuoted | null;
  formValues: FormState;
  isDone: boolean;
  isModalOpen: boolean;
  selectedBrand: Brand | null;
  selectedCategory: null | ProductCategory;
  selectedModel: Model | null;
  selectedVariant: null | Variant;
  step: number;
  voucher?: null | Voucher;
};

type TradeInAction = {
  closeModal: () => void;
  openModal: () => void;
  reset: () => void;
  setCartItemKey: (cartItemKey: string) => void;
  setCategory: (category: ProductCategory) => void;
  setDiagnosticQuoted: (price: DiagnosticQuoted) => void;
  setDone: (isDone: boolean) => void;
  setFormValues: (values: FormState) => void;
  setSelectedBrand: (brand: Brand) => void;
  setSelectedModel: (model: Model) => void;
  setSelectedVariant: (variant: Variant) => void;
  setStep: (step: number) => void;
  setTradeInOrder: (order: TradeInOrder) => void;
  setVoucher: (voucher: Voucher) => void;
};

const initialState: TradeInState = {
  action: "create",
  diagnosticQuoted: null,
  formValues: {
    productDiagnostic: {},
    selectDeliver: {
      acceptTermsAndConditions: false,
      bankAccountName: "",
      bankAccountNo: "",
      bankId: "",
      customerEmail: "",
      customerEmailConfirm: "",
      customerMobilePhone: "",
      customerName: "",
      dropoffStoreId: "",
      imeiSerialNo: "",
      pickupDropoffType: "pickup",
    },
    selectedDevice: {
      band: undefined,
      category: undefined,
      model: undefined,
      variant: undefined,
    },
  },
  isDone: false,
  isModalOpen: false,
  selectedBrand: null,
  selectedCategory: null,
  selectedModel: null,
  selectedVariant: null,
  step: 1,
  voucher: null,
};

export const useTradeInStore = create<TradeInAction & TradeInState>((set) => ({
  ...initialState,
  closeModal: () => set((state) => ({ ...state, isModalOpen: false })),
  openModal: () => set((state) => ({ ...state, isModalOpen: true })),
  reset: () => set(initialState),
  setCartItemKey: (cartItemKey) =>
    set((state) => ({ ...state, cartItemKey: cartItemKey })),
  setCategory: (category) =>
    set((state) => ({ ...state, selectedCategory: category })),
  setDiagnosticQuoted: (data) =>
    set((state) => ({ ...state, diagnosticQuoted: data })),
  setDone: (isDone) => set((state) => ({ ...state, isDone: isDone })),
  setFormValues: (values) => set((state) => ({ ...state, formValues: values })),
  setSelectedBrand: (brand) =>
    set((state) => ({ ...state, selectedBrand: brand })),
  setSelectedModel: (model) =>
    set((state) => ({ ...state, selectedModel: model })),
  setSelectedVariant: (variant) =>
    set((state) => ({ ...state, selectedVariant: variant })),
  setStep: (step) => set((state) => ({ ...state, step: step })),
  setTradeInOrder: (order) =>
    set((state) => ({ ...state, tradeInOrder: order })),
  setVoucher: (voucher) => set((state) => ({ ...state, voucher: voucher })),
}));

export default useTradeInStore;
